import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from "../components/shared/innerPageBanner";

const Feedback = () => {
  return (
    <Layout>
      <InnerPageBanner title='Feedback' maxWidth='472px' innerPageBanner={ImageIcons.feedback} />

      <section className='bg-[#f5f8fd]'>
        <div className='container'>
          <h2 className='text-center mb-[30px]'>Feedback</h2>

          <div class="grid lg:grid-cols-3 sm:grid-cols-2 gap-[20px]">
            {feedbackData.map((item) => (
              <div class="bg-[#fff] hover:bg-[#f5f8fd] hover:shadow-[0_0_35px_rgba(140,152,164,0.25)] border border-t-4 border-[#e3e6f0] hover:border-[#723f00] duration-500 rounded-lg ">
                <div class="sm:p-[25px] p-[20px] flex-grow">
                  <p class="mb-0">{item.text}</p>
                </div>
                <div className="sm:p-[25px] p-[20px] flex justify-center items-center border-t border-[#e3e6f0] group">
                  <span className="border-[4px] border-[#e3e6f0] flex justify-center items-center h-[70px] w-[70px] duration-500 relative rounded-full overflow-hidden">
                    <img src={item.image} alt="" />
                  </span>

                  <div className="pl-[20px] text-left w-[calc(100%_-_70px)]">
                    <h5 className="mb-[3px] sm:text-[20px] font-bold">{item.name}</h5>

                    <p className="mb-0">{item.designation}</p>
                  </div>
                </div>
              </div>
            ))}

          </div>



        </div>
      </section>
    </Layout>
  )
}

const feedbackData = [
  {
    name: "Premraj Kushwah",
    designation: "Customer",
    text: "I love Space! I love the ease of use, I love the fact that I have total creative freedom. The template is really nice and offers quite a large set of options. It's beautiful and the coding is done quickly and seamlessly.",
    image: ImageIcons.clinet1,
  },
  {
    name: "Navneet Singh",
    designation: "Customer",
    text: "I love Space! I love the ease of use, I love the fact that I have total creative freedom. The template is really nice and offers quite a large set of options. It's beautiful and the coding is done quickly and seamlessly.",
    image: ImageIcons.clinet4,
  },
  {
    name: "Santosh",
    designation: "Customer",
    text: "I love Space! I love the ease of use, I love the fact that I have total creative freedom. The template is really nice and offers quite a large set of options. It's beautiful and the coding is done quickly and seamlessly.",
    image: ImageIcons.clinet2,
  },
  {
    name: "Sandeep Gautam",
    designation: "Customer",
    text: "I love Space! I love the ease of use, I love the fact that I have total creative freedom. The template is really nice and offers quite a large set of options. It's beautiful and the coding is done quickly and seamlessly.",
    image: ImageIcons.clinet4,
  },
  {
    name: "Kamal Raj",
    designation: "Customer",
    text: "I love Space! I love the ease of use, I love the fact that I have total creative freedom. The template is really nice and offers quite a large set of options. It's beautiful and the coding is done quickly and seamlessly.",
    image: ImageIcons.clinet3,
  },
  {
    name: "Sandeep",
    designation: "Customer",
    text: "I love Space! I love the ease of use, I love the fact that I have total creative freedom. The template is really nice and offers quite a large set of options. It's beautiful and the coding is done quickly and seamlessly.",
    image: ImageIcons.clinet1,
  },


]

export default Feedback