import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import Layout from "../components/shared/layout";
import InnerPageBanner from '../components/shared/innerPageBanner';
import { FaCalendarAlt } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { GetRequest } from "../components/utils/request";
import { BsTwitterX } from "react-icons/bs";
import { FaPinterestP } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

const BlogDetails = () => {
  const { pathname } = useLocation();
  const [, setActiveLink] = useState('');
  const [blogs, setBlogs] = useState("");
  const { handle } = useParams();
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    GetRequest(`${process.env.REACT_APP_URL}/blogs/getBlog/${handle}`)
      .then((response) => {
        console.log(response.data)
        setBlogs(response.data)
      }).catch(err => {
        console.log(err);
      });
  }, [handle])

  useEffect(() => {
    GetRequest(`${process.env.REACT_APP_URL}/blogs/getBlogs`)
      .then((response) => {
        // console.log(">>>>>>>>", response.data.blogs)
        const activeBlogs = response.data.blogs.filter(blog => blog.isActive);
        setAllBlogs(activeBlogs);
      }).catch(err => {
        console.log(err);
      });
  }, [])

  const handleLinkClick = (blogId) => {
    setActiveLink(blogId);
  };

  return (
    <Layout>
      <InnerPageBanner title='Blog Details' maxWidth='472px' innerPageBanner={blogs.image} />

      <section className='bg-[#f5f8fd]'>
        <div className='container'>
          <div className="flex max-lg:flex-wrap justify-between xl:gap-[40px] gap-[30px]">
            <div className="lg:w-[70%] w-full border-2 border-[#ededed] md:p-[35px] p-[25px]">
              <h1>{blogs.title}</h1>
              <div className="mb-[30px] flex items-center gap-8 border-b pb-[30px] border-[#ddd]">
                <div className="flex items-center"><FaCircleUser className="text-[#309dd2] inline-block mr-[8px]" />{blogs.author}</div>
                <div className="flex items-center"><FaCalendarAlt className="text-[#309dd2] inline-block mr-[8px]" /> {blogs.publishedAt}</div>
              </div>
              <p dangerouslySetInnerHTML={{ __html: blogs.description }} />
            </div>

            <div className="lg:w-[30%] w-full">
              <div className='mb-[30px] md:p-[25px] p-[25px] border-2 border-[#ededed]'>
                <div className='title-sidebar mb-5'>
                  <h4 className="font-bold">Recent Post</h4>
                  <hr className="green-hr-line" />
                </div>

                {allBlogs?.slice(0, 4).map((item) => {
                  return (
                    <Link
                      key={item?.blogId}
                      className={`mt-[30px] flex group`}
                      to={`/blog/${item?.handle}`}
                      onClick={() => handleLinkClick(item?.blogId)}
                    >
                      <div className={`flex justify-center items-center h-[90px] w-[90px] rounded-[10px] overflow-hidden `}>
                        <img className={`h-full w-full object-cover object-center duration-500 `} src={item.image} alt={item?.imageTitle} />
                      </div>
                      <div className={`md:pl-[10px] md:ml-[10px] pl-[7px] ml-[7px] text-left sm:w-[calc(100%_-_90px)] w-[calc(100%_-_90px)] duration-500 ${pathname === `/blog/${item?.handle}` ? 'border-[#309dd2] border-l text-[#309dd2]' : 'border-[#f5f8fd] text-[#723f00] border-l'}`}>
                        <div className='mb-[5px] font-semibold'>{item?.publishedAt}</div>
                        <h5 className="mb-0 text-[17px] leading-[24px] line-clamp-2 text-black">{item?.title}</h5>
                      </div>
                    </Link>
                  );
                })}
              </div>

              <div className='mb-[30px] md:p-[25px] p-[25px] border-2 border-[#ededed]'>
                <div className='title-sidebar mb-5'>
                  <h4 className="font-bold">Stay In Touch</h4>
                  <hr className="green-hr-line" />
                </div>

                <ul className="mt-[10px]">
                  {socialMediaData.map((data, i) => (
                    <li key={i} className="inline-block sm:mr-[15px] mr-[10px] pt-[15px] last:mr-0">
                      <Link
                        to={data.url}
                        aria-label={data.aria}
                        role="button"
                        target="_blank"
                        className='text-[18px] text-[#000] hover:text-[#fff] w-[40px] h-[40px] rounded-full border border-[#000] hover:border-[#d2ac67] hover:bg-[#d2ac67] flex justify-center items-center'
                      >
                        <span>{data.icon}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const socialMediaData = [
  {
    url: "https://www.facebook.com/Anganhomes3bhkflats/",
    icon: <FaFacebookF />,
  },
  {
    url: "https://x.com/AnganHomes",
    icon: <BsTwitterX />,
  },
  {
    url: "https://www.linkedin.com/company/angan-homes/",
    icon: <FaLinkedinIn />,
  },
  {
    url: "https://www.youtube.com/@AnganHomes",
    icon: <IoLogoYoutube />,
  },
  {
    url: "https://www.pinterest.com/anganhome/",
    icon: <FaPinterestP />,
  },
  {
    url: "https://www.instagram.com/anganhomes/",
    icon: <BsInstagram />,
  },
];

export default BlogDetails