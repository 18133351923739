import React from 'react';
import { Link } from 'react-router-dom';
import { IoHome } from "react-icons/io5";

const InnerPageBanner = ({ title = "", innerPageBanner = "", breadcrumb1 = "", breadcrumbLink1 = "" }) => {
    return (
        <section className='relative md:py-[150px] max-md:py-[80px] bg-center bg-cover bg-no-repeat after:content-[""] after:absolute after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[#000] after:opacity-65 ' style={{ backgroundImage: `url(${innerPageBanner})` }}>
            <div className='heading_tittle container relative z-10'>
                {title && (
                    <h1 className="capitalize text-white text-center tracking-[2px] drop-shadow-[0_0_5px_#00000052] mb-0">{title}</h1>
                )}
                <div className='text-center lg:mt-[15px] mt-[10px]'>
                    <ul>
                        <li className='inline-block'>
                            <Link className='text-white text-[16px] flex gap-[4px]' to='/'><IoHome size={18} className='text-[#d2ac67]' /> Home</Link>
                        </li>
                        <span className='px-[10px] text-white text-[16px]'>/</span>
                        <li className='inline-block text-white text-[16px]'>{title}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default InnerPageBanner;