import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { Link } from "react-router-dom";
import { windowScroll } from "../components/utils/windowScroll";

const Sitemap = () => {
  return (
    <Layout>
      <InnerPageBanner title='Sitemap' maxWidth='472px' innerPageBanner={ImageIcons.sitemap} />

      <section>
        <div className="container">
          <ul className="text-center">
            <li>
              <label className='bg-[#000] w-full relative p-[12px] mb-[20px] inline-block after:absolute after:contant-[""] after:w-[1px] after:h-[20px] after:bg-[#000] after:top-full after:left-[50%] after:-translate-x-2/4'><Link className='text-[22px] text-white hover:text-[#d2ac67]' to="/">Home</Link></label>
              <ul className="flex justify-center max-md:flex-wrap max-md:border max-md:border-[#000] max-md:pb-[20px]">
                {navigationData.map((item, index) => (
                  <li key={index} className='relative px-[10px] max-md:w-[90%] max-md:p-0 first:pl-0 last:pr-0 first:after:w-[50%] last:after:w-[50%] after:absolute after:contant-[""] after:w-full after:border-t after:border-[#000] after:top-0 after:right-0 last:after:left-0 before:absolute before:contant-[""] before:w-[1px] before:h-[20px] before:bg-[#000] before:top-0 before:left-[50%] before:-translate-x-2/4 max-md:after:hidden max-md:before:hidden'>
                    {item.title === "Blogs" ? (
                      <Link onClick={windowScroll} to={item.type} className='text-white text-[18px] w-full text-center min-w-[100px] bg-[#000] py-[12px] px-[20px] inline-block mt-[20px] underline hover:text-[#edc57a]'>{item.title}</Link>
                    ) : (
                      <label className='text-white text-[18px] w-full text-center min-w-[100px] bg-[#000] py-[12px] px-[20px] inline-block mt-[20px]'>{item.title}</label>
                    )}
                    {item.subMenu && (
                      <ul className="max-md:border max-md:border-[#000] max-md:pb-[20px] max-md:mt-[19px]">
                        {item.subMenu.map((subItem, subIndex) => (
                          <li className='relative max-md:w-[90%] max-md:mx-auto after:absolute after:hidden first:after:block contant-[""] after:w-[1px] after:h-[20px] after:bg-[#000] after:top-0 max-md:after:top-[-20px] after:left-[50%] after:-translate-x-2/4 first:pt-[20px] pt-[10px]' key={subIndex}>
                            <Link onClick={windowScroll} className='text-[#000] hover:bg-[#d2ac67] hover:border-[#d2ac67] border w-full text-center min-w-[100px] hover:text-[#fff] border-[#000] py-[12px] px-[20px] inline-block rounded-[8px] ' to={subItem.type}>{subItem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

const navigationData = [
  {
    title: "Useful Links",
    subMenu: [
      {
        title: "About Us",
        type: "/about-us"
      },
      {
        title: "Contact Us",
        type: "/contact-us"
      },
      {
        title: "Properties",
        type: "/properties"
      },
      {
        title: "Layout",
        type: "/design-and-layout"
      },
      {
        title: "Gallery",
        type: "/gallery"
      },

    ]
  },
  {
    title: "Blogs",
    type: "/blog",
    subMenu: [
      {
        title: "Blog",
        type: "/blog"
      },

    ]
  },
  {
    title: "Other Pages",
    subMenu: [
      {
        title: "Privacy Policy",
        type: "/privacy-policy"
      },
      {
        title: "Terms & Conditions",
        type: "/terms-and-conditions"
      },
      {
        title: "Refund Policy",
        type: "/refund-policy"
      },
      {
        title: "Feedback",
        type: "/feedback"
      },
    ]
  },

];

export default Sitemap