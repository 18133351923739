import React, { useEffect, useState } from 'react';
import { IoArrowUpSharp } from 'react-icons/io5';

function ScrollToTopButton() {
    const [progress, setProgress] = useState(0);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const progressPath = document.querySelector('.progress-wrap path');
        const pathLength = progressPath.getTotalLength();
        progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
        progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

        const updateProgress = () => {
            const scroll = window.scrollY || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - window.innerHeight;
            const newProgress = pathLength - (scroll * pathLength / height);
            setProgress(newProgress);

            if (scroll > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        updateProgress();
        window.addEventListener('scroll', updateProgress);

        return () => {
            window.removeEventListener('scroll', updateProgress);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className={`' group progress-wrap 2xl:right-[50px] duration-500 right-[10px] bg-[#fff] cursor-pointer overflow-hidden fixed rounded-full w-[50px] h-[50px] shadow shadow-[#b8daf1] hover:shadow-[#8ccaf2] z-50 ' ${showButton ? 'opacity-100 overflow-visible bottom-[55px]' : '!bottom-[-55px]'}`} onClick={scrollToTop}>
           <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path className=' stroke-[#359dd0] stroke-[4] box-border transition-all duration-200 ease-linear fill-none' d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{ strokeDashoffset: progress }} />
            </svg>
            <IoArrowUpSharp className="text-[#359dd0] text-[22px] absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4" />
        </div>
    );
}

export default ScrollToTopButton;