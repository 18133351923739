import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";


const Info = () => {
    return (
        <section className="relative p-0 max-lg:py-[50px] text-white after:content-[''] after:absolute after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[#000] after:opacity-75 bg-no-repeat bg-bottom bg-cover bg-fixed xl:before:w-[37%] before:w-[40%] before:ml-[-150px] before:absolute before:h-full before:content-[''] before:z-10 before:skew-x-[-30deg] before:left-0 before:top-0 before:bg-[#723f00] max-lg:before:hidden" style={{ backgroundImage: `url(${ImageIcons.infoBg})` }}>
            <div className='container'>
                <div className='relative z-10 flex items-center max-lg:items-start gap-5 justify-between max-lg:flex-col'>
                    <div>
                        <h2 className='relative z-10 text-[30px]'>BOOK YOUR <span className='font-extrabold inline-block w-full max-lg:w-auto'>HOME HERE</span></h2>
                    </div>

                    <div className='py-[20px] max-lg:p-0 w-[600px] max-lg:w-full'>
                        <h3 className='uppercase'>Book your apartment / Flats</h3>

                        <div className="flex gap-[10px] items-center">
                            <MdCall className=" text-[30px] min-w-[30px]" />
                            <Link to='tel:9988599485' className="text-[16px]  hover:text-[#fff4a5] duration-500">91+ 9988599485</Link>
                        </div>
                        <div className="flex gap-[10px] items-center mt-3">
                            <MdEmail className=" text-[30px] min-w-[30px]" />
                            <Link to='mailto:info@anganhomes.com' className="text-[16px]  hover:text-[#fff4a5] duration-500">info@anganhomes.com</Link>
                        </div>
                    </div>

                    <div className='max-lg:hidden'>
                        <img className='mt-[-40px] max-w-[170px] max-md:max-w-[150px]' src={ImageIcons.info} alt='' />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Info